import Link from 'next/link';
import React from 'react';

type TextLinkProps = {
    variant?: 'primary' | 'foreground' | 'destructive';
    underline?: boolean;
    withArrow?: boolean;
    href: string;
    target?: string;
    children: React.ReactNode;
};

export default function TextLink({ variant, underline, withArrow, href, target, children }: TextLinkProps) {
    const getStylesFromVariant = (variant: string | undefined, underline: boolean | undefined) => {
        let className = '';
        switch (variant) {
            case 'foreground':
                className = 'text-foreground hover:text-foreground/90';
            case 'destructive':
                className = 'text-destructive hover:text-destructive/90';
            default:
                className = 'text-primary hover:text-primary/90';
        }
        if (underline) className += ` hover:underline hover:decoration-${variant}`;
        return className;
    };
    return (
        <Link
            href={href}
            target={target}
            className={`font-semibold leading-6 ${getStylesFromVariant(variant, underline)}`}
        >
            {children}
            {withArrow && (
                <>
                    {' '}
                    <span aria-hidden="true">&rarr;</span>
                </>
            )}
        </Link>
    );
}
