'use client';

import TextLink from '@/components/app-ui/TextLink';
import { Button } from '@/components/ui/button';

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
    return (
        <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
                <p className="text-base font-semibold text-destructive">500</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-destructive sm:text-5xl">Oops...</h1>
                <p className="mt-6 text-base leading-7 text-foreground max-w-xl">
                    There is an unexpected error on this page. Please try again. If the error persists, please reach out
                    to us.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <Button onClick={() => reset()}>Reload</Button>
                    <TextLink
                        variant="foreground"
                        withArrow
                        href={`${process.env.NEXT_PUBLIC_CPIO}/contact/support`}
                        target="_blank"
                    >
                        Contact support
                    </TextLink>
                </div>
            </div>
        </main>
    );
}
