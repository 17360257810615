import DeviceDetector from 'device-detector-js';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { format } from 'date-fns';
import { AES, enc } from 'crypto-js';
const ipaddr = require('ipaddr.js');
import { remark } from 'remark';
import html from 'remark-html';

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const classNames = (...classes: string[]) => {
	return classes.filter(Boolean).join(' ');
};

export const isJson = (jsonString: string) => {
	try {
		JSON.parse(jsonString);
	} catch (error) {
		return false;
	}
	return true;
};

export const getOsDevice = () => {
	const deviceDetector = new DeviceDetector();
	const userAgent = navigator.userAgent;
	const device = deviceDetector.parse(userAgent);
	return { os: device?.os?.name || '', device: device?.device?.type || '' };
};

export const isEmail = (email: string) => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
};

export const extractTranslateXY = (transformString: string) => {
	// Example: translate(-51px, 12.5px)
	const regex = /translate\((-?\d+(\.\d+)?)px,\s*(-?\d+(\.\d+)?)px\)/;
	const match = regex.exec(transformString);

	if (match) {
		// Convert the matched strings to floating point numbers
		const translateX = parseFloat(match[1]);
		const translateY = parseFloat(match[3]); // Use match[3] for translateY

		// console.log({ translateX, translateY });
		return { translateX, translateY };
	}

	// Return default value if no match is found
	// console.error('transformString', transformString);
	return { translateX: 0, translateY: 0 };
};

export function isObjectId(id: string) {
	return /^[0-9a-fA-F]{24}$/.test(id);
}

export const decodeJson = (value: string) => {
	try {
		return JSON.parse(value);
	} catch (error) {
		return false;
	}
};

const encryptString = (value: string) => {
	const encodeDecodeKey = 'cp2';
	const encryptedValue = AES.encrypt(value, encodeDecodeKey);
	return encryptedValue.toString();
};

const decryptString = (value: string) => {
	const encodeDecodeKey = 'cp2';
	return AES.decrypt(value, encodeDecodeKey).toString(enc.Utf8);
};

export const displayLocalTime = (timestamp: Date): string => {
	return format(new Date(timestamp), 'd MMM yyyy, h:mm aa');
};

export const displayLocalTimeNoYear = (timestamp: Date): string => {
	return format(new Date(timestamp), 'd MMM, h:mm aa');
};

export const displayLocalMonthYear = (timestamp: Date): string => {
	return format(new Date(timestamp), 'MMMM yyyy');
};

export const stringToNumber = (inputString: string): number => {
	let outputNumber = 0;

	for (let i = 0; i < inputString.length; i++) {
		let code = inputString.charCodeAt(i);
		outputNumber += code;
	}
	return outputNumber;
};

export const displayLocalDate = (timestamp: Date): string => {
	return format(new Date(timestamp), 'd MMMM yyyy');
};

export const daysAgo = (days: number): Date => {
	const now = new Date();
	now.setDate(now.getDate() - days);
	return now;
};

export const downloadFileFromPage = (url: string, fileName: string, newTab = true) => {
	const link = document.createElement('a');
	link.style.display = 'none';
	link.href = url;
	link.setAttribute('download', fileName);
	if (newTab) link.setAttribute('target', '_blank');
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const isIpV6 = (ipAddress: string): boolean => {
	try {
		const ipKind = ipaddr.parse(ipAddress).kind();
		return ipKind === 'ipv6';
	} catch (error) {
		return false;
	}
};

export const encryptObjectToString = (object: object): string => {
	const objectString = JSON.stringify(object);
	return encryptString(objectString);
};

export const decryptStringToObject = (encryptedString: string): any => {
	const decryptedObjectString = decryptString(encryptedString);
	try {
		return JSON.parse(decryptedObjectString);
	} catch (error) {
		return {};
	}
};

export const randomThreeDigitNumber = () => {
	return Math.floor(100 + Math.random() * 900);
};

export const convertMarkdownToHtml = async (markdown: string): Promise<string> => {
	const result = await remark().use(html).process(markdown);
	return result.toString();
};
